/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.69
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import PopularCreator from './PopularCreator';





/**
* The PopularCreators model module.
* @module model/PopularCreators
* @version 1.1.69
*/
export default class PopularCreators {
    /**
    * Constructs a new <code>PopularCreators</code>.
    * @alias module:model/PopularCreators
    * @class
    * @param creator1 {module:model/PopularCreator} 
    * @param creator2 {module:model/PopularCreator} 
    * @param creator3 {module:model/PopularCreator} 
    * @param creator4 {module:model/PopularCreator} 
    * @param creator5 {module:model/PopularCreator} 
    * @param creator6 {module:model/PopularCreator} 
    * @param creator7 {module:model/PopularCreator} 
    * @param creator8 {module:model/PopularCreator} 
    * @param creator9 {module:model/PopularCreator} 
    * @param creator10 {module:model/PopularCreator} 
    */

    constructor(creator1, creator2, creator3, creator4, creator5, creator6, creator7, creator8, creator9, creator10) {
        


        this['creator1'] = creator1;this['creator2'] = creator2;this['creator3'] = creator3;this['creator4'] = creator4;this['creator5'] = creator5;this['creator6'] = creator6;this['creator7'] = creator7;this['creator8'] = creator8;this['creator9'] = creator9;this['creator10'] = creator10;

        
    }

    /**
    * Constructs a <code>PopularCreators</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/PopularCreators} obj Optional instance to populate.
    * @return {module:model/PopularCreators} The populated <code>PopularCreators</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PopularCreators();

            
            
            if (data.hasOwnProperty('creator1')) {
                obj['creator1'] = PopularCreator.constructFromObject(data['creator1']);
            }
            if (data.hasOwnProperty('creator2')) {
                obj['creator2'] = PopularCreator.constructFromObject(data['creator2']);
            }
            if (data.hasOwnProperty('creator3')) {
                obj['creator3'] = PopularCreator.constructFromObject(data['creator3']);
            }
            if (data.hasOwnProperty('creator4')) {
                obj['creator4'] = PopularCreator.constructFromObject(data['creator4']);
            }
            if (data.hasOwnProperty('creator5')) {
                obj['creator5'] = PopularCreator.constructFromObject(data['creator5']);
            }
            if (data.hasOwnProperty('creator6')) {
                obj['creator6'] = PopularCreator.constructFromObject(data['creator6']);
            }
            if (data.hasOwnProperty('creator7')) {
                obj['creator7'] = PopularCreator.constructFromObject(data['creator7']);
            }
            if (data.hasOwnProperty('creator8')) {
                obj['creator8'] = PopularCreator.constructFromObject(data['creator8']);
            }
            if (data.hasOwnProperty('creator9')) {
                obj['creator9'] = PopularCreator.constructFromObject(data['creator9']);
            }
            if (data.hasOwnProperty('creator10')) {
                obj['creator10'] = PopularCreator.constructFromObject(data['creator10']);
            }
        }
        return obj;
    }

    /**
    * @member {module:model/PopularCreator} creator1
    */
    creator1 = undefined;
    /**
    * @member {module:model/PopularCreator} creator2
    */
    creator2 = undefined;
    /**
    * @member {module:model/PopularCreator} creator3
    */
    creator3 = undefined;
    /**
    * @member {module:model/PopularCreator} creator4
    */
    creator4 = undefined;
    /**
    * @member {module:model/PopularCreator} creator5
    */
    creator5 = undefined;
    /**
    * @member {module:model/PopularCreator} creator6
    */
    creator6 = undefined;
    /**
    * @member {module:model/PopularCreator} creator7
    */
    creator7 = undefined;
    /**
    * @member {module:model/PopularCreator} creator8
    */
    creator8 = undefined;
    /**
    * @member {module:model/PopularCreator} creator9
    */
    creator9 = undefined;
    /**
    * @member {module:model/PopularCreator} creator10
    */
    creator10 = undefined;








}


