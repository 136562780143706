/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.69
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The MediumUpdateValues model module.
* @module model/MediumUpdateValues
* @version 1.1.69
*/
export default class MediumUpdateValues {
    /**
    * Constructs a new <code>MediumUpdateValues</code>.
    * @alias module:model/MediumUpdateValues
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>MediumUpdateValues</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/MediumUpdateValues} obj Optional instance to populate.
    * @return {module:model/MediumUpdateValues} The populated <code>MediumUpdateValues</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MediumUpdateValues();

            
            
            if (data.hasOwnProperty('cpId')) {
                obj['cpId'] = ApiClient.convertToType(data['cpId'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('logo')) {
                obj['logo'] = ApiClient.convertToType(data['logo'], 'String');
            }
            if (data.hasOwnProperty('site')) {
                obj['site'] = ApiClient.convertToType(data['site'], 'String');
            }
            if (data.hasOwnProperty('displayPriority')) {
                obj['displayPriority'] = ApiClient.convertToType(data['displayPriority'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {Number} cpId
    */
    cpId = undefined;
    /**
    * @member {String} name
    */
    name = undefined;
    /**
    * @member {String} logo
    */
    logo = undefined;
    /**
    * @member {String} site
    */
    site = undefined;
    /**
    * メディア一覧の表示優先順位
    * @member {Number} displayPriority
    */
    displayPriority = undefined;








}


