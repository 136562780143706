/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.69
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import CreatorsThumbnail from './CreatorsThumbnail';
import Timestamp from './Timestamp';





/**
* The MatomeSummary model module.
* @module model/MatomeSummary
* @version 1.1.69
*/
export default class MatomeSummary {
    /**
    * Constructs a new <code>MatomeSummary</code>.
    * @alias module:model/MatomeSummary
    * @class
    * @param id {Number} 
    * @param title {String} 
    * @param thumbnail {module:model/CreatorsThumbnail} 
    * @param publishDatetime {String} 
    */

    constructor(id, title, thumbnail, publishDatetime) {
        


        this['id'] = id;this['title'] = title;this['thumbnail'] = thumbnail;this['publishDatetime'] = publishDatetime;

        
    }

    /**
    * Constructs a <code>MatomeSummary</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/MatomeSummary} obj Optional instance to populate.
    * @return {module:model/MatomeSummary} The populated <code>MatomeSummary</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MatomeSummary();

            
            
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'String');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'String');
            }
            if (data.hasOwnProperty('deletedAt')) {
                obj['deletedAt'] = ApiClient.convertToType(data['deletedAt'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('slug')) {
                obj['slug'] = ApiClient.convertToType(data['slug'], 'String');
            }
            if (data.hasOwnProperty('thumbnail')) {
                obj['thumbnail'] = CreatorsThumbnail.constructFromObject(data['thumbnail']);
            }
            if (data.hasOwnProperty('publishDatetime')) {
                obj['publishDatetime'] = ApiClient.convertToType(data['publishDatetime'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} createdAt
    */
    createdAt = undefined;
    /**
    * @member {String} updatedAt
    */
    updatedAt = undefined;
    /**
    * @member {String} deletedAt
    */
    deletedAt = undefined;
    /**
    * @member {Number} id
    */
    id = undefined;
    /**
    * @member {String} title
    */
    title = undefined;
    /**
    * @member {String} slug
    */
    slug = undefined;
    /**
    * @member {module:model/CreatorsThumbnail} thumbnail
    */
    thumbnail = undefined;
    /**
    * @member {String} publishDatetime
    */
    publishDatetime = undefined;








}


