/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.69
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import CreatorsThumbnail from './CreatorsThumbnail';
import GameCategory from './GameCategory';
import GameLocation from './GameLocation';
import GameStatus from './GameStatus';
import Timestamp from './Timestamp';





/**
* The Game model module.
* @module model/Game
* @version 1.1.69
*/
export default class Game {
    /**
    * Constructs a new <code>Game</code>.
    * @alias module:model/Game
    * @class
    * @param id {Number} 
    */

    constructor(id) {
        


        this['id'] = id;

        
    }

    /**
    * Constructs a <code>Game</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Game} obj Optional instance to populate.
    * @return {module:model/Game} The populated <code>Game</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Game();

            
            
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'String');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'String');
            }
            if (data.hasOwnProperty('deletedAt')) {
                obj['deletedAt'] = ApiClient.convertToType(data['deletedAt'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('guide')) {
                obj['guide'] = ApiClient.convertToType(data['guide'], 'String');
            }
            if (data.hasOwnProperty('originalLink')) {
                obj['originalLink'] = ApiClient.convertToType(data['originalLink'], 'String');
            }
            if (data.hasOwnProperty('displayInformation')) {
                obj['displayInformation'] = ApiClient.convertToType(data['displayInformation'], 'String');
            }
            if (data.hasOwnProperty('location')) {
                obj['location'] = GameLocation.constructFromObject(data['location']);
            }
            if (data.hasOwnProperty('order')) {
                obj['order'] = ApiClient.convertToType(data['order'], 'Number');
            }
            if (data.hasOwnProperty('publishDatetime')) {
                obj['publishDatetime'] = ApiClient.convertToType(data['publishDatetime'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = GameStatus.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('thumbnail')) {
                obj['thumbnail'] = CreatorsThumbnail.constructFromObject(data['thumbnail']);
            }
            if (data.hasOwnProperty('gameCategory')) {
                obj['gameCategory'] = GameCategory.constructFromObject(data['gameCategory']);
            }
        }
        return obj;
    }

    /**
    * @member {String} createdAt
    */
    createdAt = undefined;
    /**
    * @member {String} updatedAt
    */
    updatedAt = undefined;
    /**
    * @member {String} deletedAt
    */
    deletedAt = undefined;
    /**
    * @member {Number} id
    */
    id = undefined;
    /**
    * @member {String} title
    */
    title = undefined;
    /**
    * @member {String} description
    */
    description = undefined;
    /**
    * @member {String} guide
    */
    guide = undefined;
    /**
    * @member {String} originalLink
    */
    originalLink = undefined;
    /**
    * @member {String} displayInformation
    */
    displayInformation = undefined;
    /**
    * @member {module:model/GameLocation} location
    */
    location = undefined;
    /**
    * @member {Number} order
    */
    order = undefined;
    /**
    * @member {String} publishDatetime
    */
    publishDatetime = undefined;
    /**
    * @member {module:model/GameStatus} status
    */
    status = undefined;
    /**
    * @member {module:model/CreatorsThumbnail} thumbnail
    */
    thumbnail = undefined;
    /**
    * @member {module:model/GameCategory} gameCategory
    */
    gameCategory = undefined;








}


