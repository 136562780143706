/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.69
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Creative from '../model/Creative';
import CreativeUpdateValues from '../model/CreativeUpdateValues';
import InlineResponse201 from '../model/InlineResponse201';
import NewCreative from '../model/NewCreative';
import W2a from '../model/W2a';

/**
* W2a service.
* @module api/W2aApi
* @version 1.1.69
*/
export default class W2aApi {

    /**
    * Constructs a new W2aApi. 
    * @alias module:api/W2aApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * W2A 誘導枠の一覧
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/W2a>} and HTTP response
     */
    getW2aWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [W2a];

      return this.apiClient.callApi(
        '/w2a', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * W2A 誘導枠の一覧
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/W2a>}
     */
    getW2a() {
      return this.getW2aWithHttpInfo()
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * W2A 誘導枠に関連するクリエイティブの一覧
     * @param {Number} w2aId W2A 誘導枠の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Creative>} and HTTP response
     */
    getW2aCreativesWithHttpInfo(w2aId) {
      let postBody = null;

      // verify the required parameter 'w2aId' is set
      if (w2aId === undefined || w2aId === null) {
        throw new Error("Missing the required parameter 'w2aId' when calling getW2aCreatives");
      }


      let pathParams = {
        'w2aId': w2aId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [Creative];

      return this.apiClient.callApi(
        '/w2a/{w2aId}/creatives', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * W2A 誘導枠に関連するクリエイティブの一覧
     * @param {Number} w2aId W2A 誘導枠の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Creative>}
     */
    getW2aCreatives(w2aId) {
      return this.getW2aCreativesWithHttpInfo(w2aId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * W2A 誘導枠に関連するクリエイティブの更新
     * @param {Number} w2aId W2A 誘導枠の ID
     * @param {Number} creativeId クリエイティブの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/CreativeUpdateValues} opts.creativeUpdateValues 更新するクリエイティブの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse201} and HTTP response
     */
    patchW2aCreativeWithHttpInfo(w2aId, creativeId, opts) {
      opts = opts || {};
      let postBody = opts['creativeUpdateValues'];

      // verify the required parameter 'w2aId' is set
      if (w2aId === undefined || w2aId === null) {
        throw new Error("Missing the required parameter 'w2aId' when calling patchW2aCreative");
      }

      // verify the required parameter 'creativeId' is set
      if (creativeId === undefined || creativeId === null) {
        throw new Error("Missing the required parameter 'creativeId' when calling patchW2aCreative");
      }


      let pathParams = {
        'w2aId': w2aId,
        'creativeId': creativeId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = InlineResponse201;

      return this.apiClient.callApi(
        '/w2a/{w2aId}/creatives/{creativeId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * W2A 誘導枠に関連するクリエイティブの更新
     * @param {Number} w2aId W2A 誘導枠の ID
     * @param {Number} creativeId クリエイティブの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/CreativeUpdateValues} opts.creativeUpdateValues 更新するクリエイティブの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse201}
     */
    patchW2aCreative(w2aId, creativeId, opts) {
      return this.patchW2aCreativeWithHttpInfo(w2aId, creativeId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * W2A 誘導枠に関連するクリエイティブの追加
     * @param {Number} w2aId W2A 誘導枠の ID
     * @param {module:model/NewCreative} newCreative 追加するクリエイティブの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse201} and HTTP response
     */
    postW2aCreativeWithHttpInfo(w2aId, newCreative) {
      let postBody = newCreative;

      // verify the required parameter 'w2aId' is set
      if (w2aId === undefined || w2aId === null) {
        throw new Error("Missing the required parameter 'w2aId' when calling postW2aCreative");
      }

      // verify the required parameter 'newCreative' is set
      if (newCreative === undefined || newCreative === null) {
        throw new Error("Missing the required parameter 'newCreative' when calling postW2aCreative");
      }


      let pathParams = {
        'w2aId': w2aId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = InlineResponse201;

      return this.apiClient.callApi(
        '/w2a/{w2aId}/creatives', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * W2A 誘導枠に関連するクリエイティブの追加
     * @param {Number} w2aId W2A 誘導枠の ID
     * @param {module:model/NewCreative} newCreative 追加するクリエイティブの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse201}
     */
    postW2aCreative(w2aId, newCreative) {
      return this.postW2aCreativeWithHttpInfo(w2aId, newCreative)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
