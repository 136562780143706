/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.69
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import NewRecommendedArticle from '../model/NewRecommendedArticle';
import RecommendedArticle from '../model/RecommendedArticle';
import RecommendedArticleUpdateValues from '../model/RecommendedArticleUpdateValues';

/**
* Top service.
* @module api/TopApi
* @version 1.1.69
*/
export default class TopApi {

    /**
    * Constructs a new TopApi. 
    * @alias module:api/TopApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * トップのおすすめ記事の削除
     * @param {Number} recommendedArticleId トップのおすすめ記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteRecommendedArticleWithHttpInfo(recommendedArticleId) {
      let postBody = null;

      // verify the required parameter 'recommendedArticleId' is set
      if (recommendedArticleId === undefined || recommendedArticleId === null) {
        throw new Error("Missing the required parameter 'recommendedArticleId' when calling deleteRecommendedArticle");
      }


      let pathParams = {
        'recommendedArticleId': recommendedArticleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/top/recommendedArticles/{recommendedArticleId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * トップのおすすめ記事の削除
     * @param {Number} recommendedArticleId トップのおすすめ記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteRecommendedArticle(recommendedArticleId) {
      return this.deleteRecommendedArticleWithHttpInfo(recommendedArticleId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * トップのおすすめ記事の詳細
     * @param {Number} recommendedArticleId トップのおすすめ記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RecommendedArticle} and HTTP response
     */
    getRecommendedArticleWithHttpInfo(recommendedArticleId) {
      let postBody = null;

      // verify the required parameter 'recommendedArticleId' is set
      if (recommendedArticleId === undefined || recommendedArticleId === null) {
        throw new Error("Missing the required parameter 'recommendedArticleId' when calling getRecommendedArticle");
      }


      let pathParams = {
        'recommendedArticleId': recommendedArticleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = RecommendedArticle;

      return this.apiClient.callApi(
        '/top/recommendedArticles/{recommendedArticleId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * トップのおすすめ記事の詳細
     * @param {Number} recommendedArticleId トップのおすすめ記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RecommendedArticle}
     */
    getRecommendedArticle(recommendedArticleId) {
      return this.getRecommendedArticleWithHttpInfo(recommendedArticleId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * トップのおすすめ記事の一覧
     * 公開日時（&#x60;publishDatetime&#x60;）が未来の場合は予約中の「おすすめ記事」です。 クライアントが TRILL Admin 以外の場合、予約中の「おすすめ記事」は含まれません。 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/RecommendedArticle>} and HTTP response
     */
    getRecommendedArticlesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [RecommendedArticle];

      return this.apiClient.callApi(
        '/top/recommendedArticles', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * トップのおすすめ記事の一覧
     * 公開日時（&#x60;publishDatetime&#x60;）が未来の場合は予約中の「おすすめ記事」です。 クライアントが TRILL Admin 以外の場合、予約中の「おすすめ記事」は含まれません。 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/RecommendedArticle>}
     */
    getRecommendedArticles(opts) {
      return this.getRecommendedArticlesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * トップのおすすめ記事の更新
     * @param {Number} recommendedArticleId トップのおすすめ記事の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/RecommendedArticleUpdateValues} opts.recommendedArticleUpdateValues 更新するトップのおすすめ記事の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RecommendedArticle} and HTTP response
     */
    patchRecommendedArticleWithHttpInfo(recommendedArticleId, opts) {
      opts = opts || {};
      let postBody = opts['recommendedArticleUpdateValues'];

      // verify the required parameter 'recommendedArticleId' is set
      if (recommendedArticleId === undefined || recommendedArticleId === null) {
        throw new Error("Missing the required parameter 'recommendedArticleId' when calling patchRecommendedArticle");
      }


      let pathParams = {
        'recommendedArticleId': recommendedArticleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = RecommendedArticle;

      return this.apiClient.callApi(
        '/top/recommendedArticles/{recommendedArticleId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * トップのおすすめ記事の更新
     * @param {Number} recommendedArticleId トップのおすすめ記事の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/RecommendedArticleUpdateValues} opts.recommendedArticleUpdateValues 更新するトップのおすすめ記事の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RecommendedArticle}
     */
    patchRecommendedArticle(recommendedArticleId, opts) {
      return this.patchRecommendedArticleWithHttpInfo(recommendedArticleId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * トップのおすすめ記事の追加
     * @param {module:model/NewRecommendedArticle} newRecommendedArticle 追加するトップのおすすめ記事の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RecommendedArticle} and HTTP response
     */
    postRecommendedArticleWithHttpInfo(newRecommendedArticle) {
      let postBody = newRecommendedArticle;

      // verify the required parameter 'newRecommendedArticle' is set
      if (newRecommendedArticle === undefined || newRecommendedArticle === null) {
        throw new Error("Missing the required parameter 'newRecommendedArticle' when calling postRecommendedArticle");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = RecommendedArticle;

      return this.apiClient.callApi(
        '/top/recommendedArticles', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * トップのおすすめ記事の追加
     * @param {module:model/NewRecommendedArticle} newRecommendedArticle 追加するトップのおすすめ記事の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RecommendedArticle}
     */
    postRecommendedArticle(newRecommendedArticle) {
      return this.postRecommendedArticleWithHttpInfo(newRecommendedArticle)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * トップのおすすめ記事の復元
     * @param {Number} recommendedArticleId トップのおすすめ記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putRecommendedArticleWithHttpInfo(recommendedArticleId) {
      let postBody = null;

      // verify the required parameter 'recommendedArticleId' is set
      if (recommendedArticleId === undefined || recommendedArticleId === null) {
        throw new Error("Missing the required parameter 'recommendedArticleId' when calling putRecommendedArticle");
      }


      let pathParams = {
        'recommendedArticleId': recommendedArticleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/top/recommendedArticles/{recommendedArticleId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * トップのおすすめ記事の復元
     * @param {Number} recommendedArticleId トップのおすすめ記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putRecommendedArticle(recommendedArticleId) {
      return this.putRecommendedArticleWithHttpInfo(recommendedArticleId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
