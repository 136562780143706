/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.69
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import CouponImage from './CouponImage';
import CouponStatus from './CouponStatus';





/**
* The CouponUpdateValues model module.
* @module model/CouponUpdateValues
* @version 1.1.69
*/
export default class CouponUpdateValues {
    /**
    * Constructs a new <code>CouponUpdateValues</code>.
    * @alias module:model/CouponUpdateValues
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>CouponUpdateValues</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/CouponUpdateValues} obj Optional instance to populate.
    * @return {module:model/CouponUpdateValues} The populated <code>CouponUpdateValues</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CouponUpdateValues();

            
            
            if (data.hasOwnProperty('couponBrandId')) {
                obj['couponBrandId'] = ApiClient.convertToType(data['couponBrandId'], 'Number');
            }
            if (data.hasOwnProperty('code')) {
                obj['code'] = ApiClient.convertToType(data['code'], 'String');
            }
            if (data.hasOwnProperty('image')) {
                obj['image'] = CouponImage.constructFromObject(data['image']);
            }
            if (data.hasOwnProperty('notes')) {
                obj['notes'] = ApiClient.convertToType(data['notes'], 'String');
            }
            if (data.hasOwnProperty('termsOfUse')) {
                obj['termsOfUse'] = ApiClient.convertToType(data['termsOfUse'], 'String');
            }
            if (data.hasOwnProperty('startDateOfUse')) {
                obj['startDateOfUse'] = ApiClient.convertToType(data['startDateOfUse'], 'String');
            }
            if (data.hasOwnProperty('endDateOfUse')) {
                obj['endDateOfUse'] = ApiClient.convertToType(data['endDateOfUse'], 'String');
            }
            if (data.hasOwnProperty('weight')) {
                obj['weight'] = ApiClient.convertToType(data['weight'], 'Number');
            }
            if (data.hasOwnProperty('priority')) {
                obj['priority'] = ApiClient.convertToType(data['priority'], 'Number');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = CouponStatus.constructFromObject(data['status']);
            }
        }
        return obj;
    }

    /**
    * @member {Number} couponBrandId
    */
    couponBrandId = undefined;
    /**
    * @member {String} code
    */
    code = undefined;
    /**
    * @member {module:model/CouponImage} image
    */
    image = undefined;
    /**
    * @member {String} notes
    */
    notes = undefined;
    /**
    * @member {String} termsOfUse
    */
    termsOfUse = undefined;
    /**
    * @member {String} startDateOfUse
    */
    startDateOfUse = undefined;
    /**
    * @member {String} endDateOfUse
    */
    endDateOfUse = undefined;
    /**
    * @member {Number} weight
    */
    weight = undefined;
    /**
    * @member {Number} priority
    */
    priority = undefined;
    /**
    * @member {module:model/CouponStatus} status
    */
    status = undefined;








}


