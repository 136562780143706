/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.69
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CategoryUpdateValues1 from '../model/CategoryUpdateValues1';
import ManagementCategory from '../model/ManagementCategory';
import NewCategory1 from '../model/NewCategory1';

/**
* ManagementCategory service.
* @module api/ManagementCategoryApi
* @version 1.1.69
*/
export default class ManagementCategoryApi {

    /**
    * Constructs a new ManagementCategoryApi. 
    * @alias module:api/ManagementCategoryApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * カテゴリの削除
     * @param {Number} managementCategoryId カテゴリの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteManagementCategoryWithHttpInfo(managementCategoryId) {
      let postBody = null;

      // verify the required parameter 'managementCategoryId' is set
      if (managementCategoryId === undefined || managementCategoryId === null) {
        throw new Error("Missing the required parameter 'managementCategoryId' when calling deleteManagementCategory");
      }


      let pathParams = {
        'managementCategoryId': managementCategoryId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/managementCategories/{managementCategoryId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * カテゴリの削除
     * @param {Number} managementCategoryId カテゴリの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteManagementCategory(managementCategoryId) {
      return this.deleteManagementCategoryWithHttpInfo(managementCategoryId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * カテゴリの一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ManagementCategory>} and HTTP response
     */
    getManagementCategoriesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [ManagementCategory];

      return this.apiClient.callApi(
        '/managementCategories', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * カテゴリの一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ManagementCategory>}
     */
    getManagementCategories(opts) {
      return this.getManagementCategoriesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * カテゴリの詳細
     * @param {Number} managementCategoryId カテゴリの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ManagementCategory} and HTTP response
     */
    getManagementCategoryWithHttpInfo(managementCategoryId) {
      let postBody = null;

      // verify the required parameter 'managementCategoryId' is set
      if (managementCategoryId === undefined || managementCategoryId === null) {
        throw new Error("Missing the required parameter 'managementCategoryId' when calling getManagementCategory");
      }


      let pathParams = {
        'managementCategoryId': managementCategoryId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = ManagementCategory;

      return this.apiClient.callApi(
        '/managementCategories/{managementCategoryId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * カテゴリの詳細
     * @param {Number} managementCategoryId カテゴリの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ManagementCategory}
     */
    getManagementCategory(managementCategoryId) {
      return this.getManagementCategoryWithHttpInfo(managementCategoryId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * カテゴリの更新
     * @param {Number} managementCategoryId カテゴリの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/CategoryUpdateValues1} opts.categoryUpdateValues 更新するカテゴリの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ManagementCategory} and HTTP response
     */
    patchManagementCategoryWithHttpInfo(managementCategoryId, opts) {
      opts = opts || {};
      let postBody = opts['categoryUpdateValues'];

      // verify the required parameter 'managementCategoryId' is set
      if (managementCategoryId === undefined || managementCategoryId === null) {
        throw new Error("Missing the required parameter 'managementCategoryId' when calling patchManagementCategory");
      }


      let pathParams = {
        'managementCategoryId': managementCategoryId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = ManagementCategory;

      return this.apiClient.callApi(
        '/managementCategories/{managementCategoryId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * カテゴリの更新
     * @param {Number} managementCategoryId カテゴリの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/CategoryUpdateValues1} opts.categoryUpdateValues 更新するカテゴリの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ManagementCategory}
     */
    patchManagementCategory(managementCategoryId, opts) {
      return this.patchManagementCategoryWithHttpInfo(managementCategoryId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * カテゴリの追加
     * @param {module:model/NewCategory1} newCategory 追加するカテゴリの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ManagementCategory} and HTTP response
     */
    postManagementCategoryWithHttpInfo(newCategory) {
      let postBody = newCategory;

      // verify the required parameter 'newCategory' is set
      if (newCategory === undefined || newCategory === null) {
        throw new Error("Missing the required parameter 'newCategory' when calling postManagementCategory");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = ManagementCategory;

      return this.apiClient.callApi(
        '/managementCategories', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * カテゴリの追加
     * @param {module:model/NewCategory1} newCategory 追加するカテゴリの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ManagementCategory}
     */
    postManagementCategory(newCategory) {
      return this.postManagementCategoryWithHttpInfo(newCategory)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * カテゴリの復元
     * @param {Number} managementCategoryId カテゴリの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putManagementCategoryWithHttpInfo(managementCategoryId) {
      let postBody = null;

      // verify the required parameter 'managementCategoryId' is set
      if (managementCategoryId === undefined || managementCategoryId === null) {
        throw new Error("Missing the required parameter 'managementCategoryId' when calling putManagementCategory");
      }


      let pathParams = {
        'managementCategoryId': managementCategoryId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/managementCategories/{managementCategoryId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * カテゴリの復元
     * @param {Number} managementCategoryId カテゴリの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putManagementCategory(managementCategoryId) {
      return this.putManagementCategoryWithHttpInfo(managementCategoryId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
