/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.69
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Game from '../model/Game';
import GameUpdateValues from '../model/GameUpdateValues';
import NewGame from '../model/NewGame';

/**
* Game service.
* @module api/GameApi
* @version 1.1.69
*/
export default class GameApi {

    /**
    * Constructs a new GameApi. 
    * @alias module:api/GameApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * ゲームの削除
     * @param {Number} gameId ゲームの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteGameWithHttpInfo(gameId) {
      let postBody = null;

      // verify the required parameter 'gameId' is set
      if (gameId === undefined || gameId === null) {
        throw new Error("Missing the required parameter 'gameId' when calling deleteGame");
      }


      let pathParams = {
        'gameId': gameId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/games/{gameId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ゲームの削除
     * @param {Number} gameId ゲームの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteGame(gameId) {
      return this.deleteGameWithHttpInfo(gameId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * ゲームの詳細
     * @param {Number} gameId ゲームの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Game} and HTTP response
     */
    getGameWithHttpInfo(gameId) {
      let postBody = null;

      // verify the required parameter 'gameId' is set
      if (gameId === undefined || gameId === null) {
        throw new Error("Missing the required parameter 'gameId' when calling getGame");
      }


      let pathParams = {
        'gameId': gameId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = Game;

      return this.apiClient.callApi(
        '/games/{gameId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ゲームの詳細
     * @param {Number} gameId ゲームの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Game}
     */
    getGame(gameId) {
      return this.getGameWithHttpInfo(gameId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * ゲームの一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Game>} and HTTP response
     */
    getGamesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [Game];

      return this.apiClient.callApi(
        '/games', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ゲームの一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Game>}
     */
    getGames(opts) {
      return this.getGamesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * ゲームの更新
     * @param {Number} gameId ゲームの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/GameUpdateValues} opts.gameUpdateValues 更新するゲームの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Game} and HTTP response
     */
    patchGameWithHttpInfo(gameId, opts) {
      opts = opts || {};
      let postBody = opts['gameUpdateValues'];

      // verify the required parameter 'gameId' is set
      if (gameId === undefined || gameId === null) {
        throw new Error("Missing the required parameter 'gameId' when calling patchGame");
      }


      let pathParams = {
        'gameId': gameId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = Game;

      return this.apiClient.callApi(
        '/games/{gameId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ゲームの更新
     * @param {Number} gameId ゲームの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/GameUpdateValues} opts.gameUpdateValues 更新するゲームの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Game}
     */
    patchGame(gameId, opts) {
      return this.patchGameWithHttpInfo(gameId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * ゲームの追加
     * @param {module:model/NewGame} newGame 追加するゲームの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Game} and HTTP response
     */
    postGameWithHttpInfo(newGame) {
      let postBody = newGame;

      // verify the required parameter 'newGame' is set
      if (newGame === undefined || newGame === null) {
        throw new Error("Missing the required parameter 'newGame' when calling postGame");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = Game;

      return this.apiClient.callApi(
        '/games', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ゲームの追加
     * @param {module:model/NewGame} newGame 追加するゲームの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Game}
     */
    postGame(newGame) {
      return this.postGameWithHttpInfo(newGame)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * ゲームの復元
     * @param {Number} gameId ゲームの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putGameWithHttpInfo(gameId) {
      let postBody = null;

      // verify the required parameter 'gameId' is set
      if (gameId === undefined || gameId === null) {
        throw new Error("Missing the required parameter 'gameId' when calling putGame");
      }


      let pathParams = {
        'gameId': gameId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/games/{gameId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ゲームの復元
     * @param {Number} gameId ゲームの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putGame(gameId) {
      return this.putGameWithHttpInfo(gameId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
