/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.69
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import CreatorsThumbnail from './CreatorsThumbnail';





/**
* The PersonalityQuizResult model module.
* @module model/PersonalityQuizResult
* @version 1.1.69
*/
export default class PersonalityQuizResult {
    /**
    * Constructs a new <code>PersonalityQuizResult</code>.
    * @alias module:model/PersonalityQuizResult
    * @class
    * @param resultPoint {Number} 合計点範囲（最小〜最大）の最大の方
    * @param cover {module:model/CreatorsThumbnail} 
    * @param summary {String} 
    * @param description {String} 
    */

    constructor(resultPoint, cover, summary, description) {
        


        this['resultPoint'] = resultPoint;this['cover'] = cover;this['summary'] = summary;this['description'] = description;

        
    }

    /**
    * Constructs a <code>PersonalityQuizResult</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/PersonalityQuizResult} obj Optional instance to populate.
    * @return {module:model/PersonalityQuizResult} The populated <code>PersonalityQuizResult</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PersonalityQuizResult();

            
            
            if (data.hasOwnProperty('resultPoint')) {
                obj['resultPoint'] = ApiClient.convertToType(data['resultPoint'], 'Number');
            }
            if (data.hasOwnProperty('cover')) {
                obj['cover'] = CreatorsThumbnail.constructFromObject(data['cover']);
            }
            if (data.hasOwnProperty('summary')) {
                obj['summary'] = ApiClient.convertToType(data['summary'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('dynamicLink')) {
                obj['dynamicLink'] = ApiClient.convertToType(data['dynamicLink'], 'String');
            }
        }
        return obj;
    }

    /**
    * 合計点範囲（最小〜最大）の最大の方
    * @member {Number} resultPoint
    */
    resultPoint = undefined;
    /**
    * @member {module:model/CreatorsThumbnail} cover
    */
    cover = undefined;
    /**
    * @member {String} summary
    */
    summary = undefined;
    /**
    * @member {String} description
    */
    description = undefined;
    /**
    * @member {String} dynamicLink
    */
    dynamicLink = undefined;








}


