/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.69
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Error from '../model/Error';
import SignUpInfo from '../model/SignUpInfo';
import Token from '../model/Token';

/**
* Auth service.
* @module api/AuthApi
* @version 1.1.69
*/
export default class AuthApi {

    /**
    * Constructs a new AuthApi. 
    * @alias module:api/AuthApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * トークンの発行・再発行
     * ### トークンの発行  アカウント登録で取得した &#x60;&lt;username&gt;/&lt;password&gt;&#x60; を用いて、API 呼び出しに必要なトークンを発行します。  ### トークンの利用  以後の API 呼び出しには &#x60;&lt;access-token&gt;&#x60; を &#x60;Authorization&#x60; リクエストヘッダーに指定して行います。  &#x60;&#x60;&#x60; &lt;api-method&gt; /api/&lt;api-path&gt; HTTP/1.1 Authorization: Bearer &lt;access-token&gt; &#x60;&#x60;&#x60;  ### トークンの再発行  &#x60;&lt;access-token&gt;&#x60; の有効期限が過ぎた場合は &#x60;&lt;refresh-token&gt;&#x60; で再発行します。  --- 
     * @param {module:model/String} grantType トークン発行の場合は &#x60;password&#x60; 、トークン再発行の場合は &#x60;refresh_token&#x60;
     * @param {String} clientId TRILL iOS / TRILL Android / TRILL Web / TRILL Admin 別の既定のクライアント ID
     * @param {Object} opts Optional parameters
     * @param {String} opts.username トークン発行の場合
     * @param {String} opts.password トークン発行の場合
     * @param {String} opts.refreshToken トークン再発行の場合
     * @param {String} opts.scope トークンに付与するスコープ（スペース区切りで複数指定出来る。指定しなかった場合は、ユーザーのロールに対応する既定のスコープが付与される）
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Token} and HTTP response
     */
    publishTokenWithHttpInfo(grantType, clientId, opts) {
      opts = opts || {};
      let postBody = null;

      // verify the required parameter 'grantType' is set
      if (grantType === undefined || grantType === null) {
        throw new Error("Missing the required parameter 'grantType' when calling publishToken");
      }

      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling publishToken");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'grant_type': grantType,
        'client_id': clientId,
        'username': opts['username'],
        'password': opts['password'],
        'refresh_token': opts['refreshToken'],
        'scope': opts['scope']
      };

      let authNames = [];
      let contentTypes = ['application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = Token;

      return this.apiClient.callApi(
        '/oauth/token', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * トークンの発行・再発行
     * ### トークンの発行  アカウント登録で取得した &#x60;&lt;username&gt;/&lt;password&gt;&#x60; を用いて、API 呼び出しに必要なトークンを発行します。  ### トークンの利用  以後の API 呼び出しには &#x60;&lt;access-token&gt;&#x60; を &#x60;Authorization&#x60; リクエストヘッダーに指定して行います。  &#x60;&#x60;&#x60; &lt;api-method&gt; /api/&lt;api-path&gt; HTTP/1.1 Authorization: Bearer &lt;access-token&gt; &#x60;&#x60;&#x60;  ### トークンの再発行  &#x60;&lt;access-token&gt;&#x60; の有効期限が過ぎた場合は &#x60;&lt;refresh-token&gt;&#x60; で再発行します。  --- 
     * @param {module:model/String} grantType トークン発行の場合は &#x60;password&#x60; 、トークン再発行の場合は &#x60;refresh_token&#x60;
     * @param {String} clientId TRILL iOS / TRILL Android / TRILL Web / TRILL Admin 別の既定のクライアント ID
     * @param {Object} opts Optional parameters
     * @param {String} opts.username トークン発行の場合
     * @param {String} opts.password トークン発行の場合
     * @param {String} opts.refreshToken トークン再発行の場合
     * @param {String} opts.scope トークンに付与するスコープ（スペース区切りで複数指定出来る。指定しなかった場合は、ユーザーのロールに対応する既定のスコープが付与される）
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Token}
     */
    publishToken(grantType, clientId, opts) {
      return this.publishTokenWithHttpInfo(grantType, clientId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * アカウント登録
     * アカウント登録情報（メールアドレス・パスワード）を &#x60;&lt;username&gt;/&lt;password&gt;&#x60; として登録します。
     * @param {module:model/SignUpInfo} signUpInfo アカウント登録情報
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    signUpWithHttpInfo(signUpInfo) {
      let postBody = signUpInfo;

      // verify the required parameter 'signUpInfo' is set
      if (signUpInfo === undefined || signUpInfo === null) {
        throw new Error("Missing the required parameter 'signUpInfo' when calling signUp");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/signUp', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * アカウント登録
     * アカウント登録情報（メールアドレス・パスワード）を &#x60;&lt;username&gt;/&lt;password&gt;&#x60; として登録します。
     * @param {module:model/SignUpInfo} signUpInfo アカウント登録情報
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    signUp(signUpInfo) {
      return this.signUpWithHttpInfo(signUpInfo)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
