/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.69
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The CouponImage model module.
* @module model/CouponImage
* @version 1.1.69
*/
export default class CouponImage {
    /**
    * Constructs a new <code>CouponImage</code>.
    * @alias module:model/CouponImage
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>CouponImage</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/CouponImage} obj Optional instance to populate.
    * @return {module:model/CouponImage} The populated <code>CouponImage</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CouponImage();

            
            
            if (data.hasOwnProperty('thumbnail')) {
                obj['thumbnail'] = ApiClient.convertToType(data['thumbnail'], 'String');
            }
            if (data.hasOwnProperty('coupon')) {
                obj['coupon'] = ApiClient.convertToType(data['coupon'], 'String');
            }
        }
        return obj;
    }

    /**
    * request:base64URL、response:ServingURL
    * @member {String} thumbnail
    */
    thumbnail = undefined;
    /**
    * request:base64URL、response:ServingURL
    * @member {String} coupon
    */
    coupon = undefined;








}


