/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.69
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import NewSponsor from '../model/NewSponsor';
import Sponsor from '../model/Sponsor';
import SponsorUpdateValues from '../model/SponsorUpdateValues';

/**
* Sponsor service.
* @module api/SponsorApi
* @version 1.1.69
*/
export default class SponsorApi {

    /**
    * Constructs a new SponsorApi. 
    * @alias module:api/SponsorApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * スポンサーの削除
     * @param {Number} sponsorId スポンサーの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteSponsorWithHttpInfo(sponsorId) {
      let postBody = null;

      // verify the required parameter 'sponsorId' is set
      if (sponsorId === undefined || sponsorId === null) {
        throw new Error("Missing the required parameter 'sponsorId' when calling deleteSponsor");
      }


      let pathParams = {
        'sponsorId': sponsorId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/sponsors/{sponsorId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * スポンサーの削除
     * @param {Number} sponsorId スポンサーの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteSponsor(sponsorId) {
      return this.deleteSponsorWithHttpInfo(sponsorId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * スポンサーの詳細
     * @param {Number} sponsorId スポンサーの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Sponsor} and HTTP response
     */
    getSponsorWithHttpInfo(sponsorId) {
      let postBody = null;

      // verify the required parameter 'sponsorId' is set
      if (sponsorId === undefined || sponsorId === null) {
        throw new Error("Missing the required parameter 'sponsorId' when calling getSponsor");
      }


      let pathParams = {
        'sponsorId': sponsorId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = Sponsor;

      return this.apiClient.callApi(
        '/sponsors/{sponsorId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * スポンサーの詳細
     * @param {Number} sponsorId スポンサーの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Sponsor}
     */
    getSponsor(sponsorId) {
      return this.getSponsorWithHttpInfo(sponsorId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * スポンサーの一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Sponsor>} and HTTP response
     */
    getSponsorsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [Sponsor];

      return this.apiClient.callApi(
        '/sponsors', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * スポンサーの一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Sponsor>}
     */
    getSponsors(opts) {
      return this.getSponsorsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * スポンサーの更新
     * @param {Number} sponsorId スポンサーの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/SponsorUpdateValues} opts.sponsorUpdateValues 更新するスポンサーの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Sponsor} and HTTP response
     */
    patchSponsorWithHttpInfo(sponsorId, opts) {
      opts = opts || {};
      let postBody = opts['sponsorUpdateValues'];

      // verify the required parameter 'sponsorId' is set
      if (sponsorId === undefined || sponsorId === null) {
        throw new Error("Missing the required parameter 'sponsorId' when calling patchSponsor");
      }


      let pathParams = {
        'sponsorId': sponsorId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = Sponsor;

      return this.apiClient.callApi(
        '/sponsors/{sponsorId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * スポンサーの更新
     * @param {Number} sponsorId スポンサーの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/SponsorUpdateValues} opts.sponsorUpdateValues 更新するスポンサーの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Sponsor}
     */
    patchSponsor(sponsorId, opts) {
      return this.patchSponsorWithHttpInfo(sponsorId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * スポンサーの追加
     * @param {module:model/NewSponsor} newSponsor 追加するスポンサーの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Sponsor} and HTTP response
     */
    postSponsorWithHttpInfo(newSponsor) {
      let postBody = newSponsor;

      // verify the required parameter 'newSponsor' is set
      if (newSponsor === undefined || newSponsor === null) {
        throw new Error("Missing the required parameter 'newSponsor' when calling postSponsor");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = Sponsor;

      return this.apiClient.callApi(
        '/sponsors', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * スポンサーの追加
     * @param {module:model/NewSponsor} newSponsor 追加するスポンサーの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Sponsor}
     */
    postSponsor(newSponsor) {
      return this.postSponsorWithHttpInfo(newSponsor)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * スポンサーの復元
     * @param {Number} sponsorId スポンサーの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putSponsorWithHttpInfo(sponsorId) {
      let postBody = null;

      // verify the required parameter 'sponsorId' is set
      if (sponsorId === undefined || sponsorId === null) {
        throw new Error("Missing the required parameter 'sponsorId' when calling putSponsor");
      }


      let pathParams = {
        'sponsorId': sponsorId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/sponsors/{sponsorId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * スポンサーの復元
     * @param {Number} sponsorId スポンサーの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putSponsor(sponsorId) {
      return this.putSponsorWithHttpInfo(sponsorId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
