/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.69
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import CouponBrandImage from './CouponBrandImage';
import CouponContact from './CouponContact';





/**
* The NewCouponBrand model module.
* @module model/NewCouponBrand
* @version 1.1.69
*/
export default class NewCouponBrand {
    /**
    * Constructs a new <code>NewCouponBrand</code>.
    * @alias module:model/NewCouponBrand
    * @class
    * @param couponCompanyId {Number} 
    * @param name {String} 
    * @param displayName {String} 
    */

    constructor(couponCompanyId, name, displayName) {
        


        this['couponCompanyId'] = couponCompanyId;this['name'] = name;this['displayName'] = displayName;

        
    }

    /**
    * Constructs a <code>NewCouponBrand</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/NewCouponBrand} obj Optional instance to populate.
    * @return {module:model/NewCouponBrand} The populated <code>NewCouponBrand</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new NewCouponBrand();

            
            
            if (data.hasOwnProperty('couponCompanyId')) {
                obj['couponCompanyId'] = ApiClient.convertToType(data['couponCompanyId'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('displayName')) {
                obj['displayName'] = ApiClient.convertToType(data['displayName'], 'String');
            }
            if (data.hasOwnProperty('priority')) {
                obj['priority'] = ApiClient.convertToType(data['priority'], 'Number');
            }
            if (data.hasOwnProperty('contact')) {
                obj['contact'] = CouponContact.constructFromObject(data['contact']);
            }
            if (data.hasOwnProperty('image')) {
                obj['image'] = CouponBrandImage.constructFromObject(data['image']);
            }
        }
        return obj;
    }

    /**
    * @member {Number} couponCompanyId
    */
    couponCompanyId = undefined;
    /**
    * @member {String} name
    */
    name = undefined;
    /**
    * @member {String} displayName
    */
    displayName = undefined;
    /**
    * @member {Number} priority
    */
    priority = undefined;
    /**
    * @member {module:model/CouponContact} contact
    */
    contact = undefined;
    /**
    * @member {module:model/CouponBrandImage} image
    */
    image = undefined;








}


