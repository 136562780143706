/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.69
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The Token model module.
* @module model/Token
* @version 1.1.69
*/
export default class Token {
    /**
    * Constructs a new <code>Token</code>.
    * @alias module:model/Token
    * @class
    * @param accessToken {String} 
    * @param expiresIn {Number} トークンの有効期限（秒単位）
    * @param refreshToken {String} 
    */

    constructor(accessToken, expiresIn, refreshToken) {
        


        this['access_token'] = accessToken;this['expires_in'] = expiresIn;this['refresh_token'] = refreshToken;

        
    }

    /**
    * Constructs a <code>Token</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Token} obj Optional instance to populate.
    * @return {module:model/Token} The populated <code>Token</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Token();

            
            
            if (data.hasOwnProperty('access_token')) {
                obj['access_token'] = ApiClient.convertToType(data['access_token'], 'String');
            }
            if (data.hasOwnProperty('token_type')) {
                obj['token_type'] = ApiClient.convertToType(data['token_type'], 'String');
            }
            if (data.hasOwnProperty('expires_in')) {
                obj['expires_in'] = ApiClient.convertToType(data['expires_in'], 'Number');
            }
            if (data.hasOwnProperty('refresh_token')) {
                obj['refresh_token'] = ApiClient.convertToType(data['refresh_token'], 'String');
            }
            if (data.hasOwnProperty('scope')) {
                obj['scope'] = ApiClient.convertToType(data['scope'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} access_token
    */
    access_token = undefined;
    /**
    * トークンのタイプ（`bearer` 固定）
    * @member {String} token_type
    */
    token_type = undefined;
    /**
    * トークンの有効期限（秒単位）
    * @member {Number} expires_in
    */
    expires_in = undefined;
    /**
    * @member {String} refresh_token
    */
    refresh_token = undefined;
    /**
    * @member {String} scope
    */
    scope = undefined;








}


